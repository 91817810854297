<script setup>
    import { defineProps, defineEmits, onMounted } from 'vue';
    import { inject, ref, computed } from "vue";
    import { InjectionKeys } from 'o365-utils';

    const { pwaStoreKey } = InjectionKeys;

    const { state: offlineState, startSync } = inject(pwaStoreKey);

    const props = defineProps({
        backgroundColor: {
            required: false,
            default: "bg-white",
            type: String
        },
        syncType: {
            required: true,
            type: String
        },
        isDisabled: {
            required: false,
            type: Boolean,
            default: false,
        },
        buttonText: {
            required: false,
            type: String
        }
    });
    const offlineDefinition = computed(() => {
      return offlineState.syncDefinitions.get('OFFLINE-SYNC');
    });
    const onlineDefinition = computed(() => {
      return offlineState.syncDefinitions.get('ONLINE-SYNC');
    });
    const buttonText = computed(() => {
        if(props.buttonText){
            return props.buttonText;
        }
        switch (props.syncType) {
            case "OFFLINE-SYNC":
                return offlineDefinition.value?.buttonText ?? offlineDefinition.value.title;
            case "ONLINE-SYNC":
                return onlineDefinition.value?.buttonText ?? onlineDefinition.value.title;
            default:
            return "";
        }
    });
    const iconClass = computed(() => {
        switch (props.syncType) {
            case "OFFLINE-SYNC":
                return offlineDefinition.value?.iconClass ?? "";
            case "ONLINE-SYNC":
                return onlineDefinition.value?.iconClass ?? "";
            default:
            return "";
        }
    });
    
    const handleSync = async (syncType) => {
        if(!offlineState?.pwaState?.isAppInstalled) return;
        await startSync(syncType);
    }
</script>

<template>
    <div class="d-flex justify-content-center align-items-center rounded border shadow-sm" :class="props.backgroundColor, isDisabled ? 'disabled' : 'cursor-pointer'"
         :style="{cursor: isDisabled ? 'unset' : 'pointer'}" @click="!isDisabled && handleSync(syncType)">
        <component :is="isDisabled ? 'span' : 'a'" class="d-flex justify-content-center align-items-center fw-semibold gap-1 p-2 no-style">
            <i :class="iconClass"></i>
            <span>{{ buttonText }}</span>
        </component>
    </div>
</template>

<style scoped>
a.no-style {
    text-decoration: none;
}

a.no-style:hover, a.no-style:focus, a.no-style:active, a.no-style:visited {
    text-decoration: none;
}
.disabled {
    cursor: unset;
    opacity: 70%; 
}
.cursor-pointer {
    cursor: pointer;
}
</style>